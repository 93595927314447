.image-card {
  // border: 1px solid lightblue;

  // margin: 1rem 0;
  // display: flex;
  // align-items: center;

  img {
    height:auto;
    width: 100%;

    object-fit: cover;
    // border-radius: 10px;
  }
}
