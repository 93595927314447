// 
// carousel.scss
//

// carousel indicators style
.carousel-indicators {
    margin-bottom: -2px;
    [data-bs-target] {
        box-sizing: content-box;
        flex: 0 1 auto;
        width: 8px;
        height: 8px;
        padding: 0;
        border-radius: 100%;
        margin-right: 3px;
        margin-left: 3px;
        text-indent: -999px;
        cursor: pointer;
        background-color: $gray-500!important;
        background-clip: padding-box;
        border: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        opacity: 0.5;
        &.active {
            opacity: 1;
            background-color: $primary!important;
        }
    }
}
.upcoming-carousel {
    .carousel-control-prev,
    .carousel-control-next {
        opacity: 1;
        top: calc(50% - 48px);
        bottom: auto;
        width: auto;
        width: 32px;
        height: 64px;
        background-color: rgba(230,230,230,0.5);
        transition: all .3s ease;
        &:hover { background-color: $primary; cursor: pointer;
         path { fill: white; stroke: white;}
        }
    }
    .carousel-control-next { right:1px; border-top-left-radius: 8px; border-bottom-left-radius: 8px; }
    .carousel-control-prev { left:1px; border-top-right-radius: 8px; border-bottom-right-radius: 8px; }
}
