///////////////////////////////////////////////////
// CREATE AUDIO RELEASE FORM
///////////////////////////////////////////////////
.svg_image {
  width: 100%;
  max-width: 80px;
}
.release_img_mini {
  overflow: hidden;
  .img-wrap {
    width: 40px;
    height: 0;
    padding-bottom: $aspect-ratio-1-1;
    display: block;
    position: relative;
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      left: -1000%;
      right: -1000%;
      top: -1000%;
      bottom: -1000%;
      margin: auto;
      -o-object-fit: cover;
      object-fit: cover;
    }
  }
}
.release_img {
  overflow: hidden;
  .img-wrap {
    width: 100%;
    height: 0;
    padding-bottom: $aspect-ratio-1-1;
    display: block;
    position: relative;
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      left: -1000%;
      right: -1000%;
      top: -1000%;
      bottom: -1000%;
      margin: auto;
      -o-object-fit: cover;
      object-fit: cover;
    }
  }
}
@media (max-width: 768px) {
  .release-main {
    > .card,
    > .card .card-header {
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }
  }
}
.release-sidebar {
  max-width: 160px !important;
  .card {
    background: $gray-50;
  }
  .step {
    position: relative;
    text-decoration: none;
    color: $gray-700;
    cursor: default;
    transition: $transition-base;
    &::before {
      content: '';
      position: absolute;
      left: 50%;
      bottom: -2rem;
      width: 2px;
      height: 1.5rem;
      background: $gray-300;
      margin-left: -1px;
      opacity: 0.66;
    }
    &:nth-last-child(1) {
      &::before {
        display: none;
      }
    }
    &:focus,
    &:hover {
      color: $primary;
    }
    h3 {
      div {
        height: 52px;
        width: 52px;
        margin: 0 auto;
        display: block;
        line-height: 52px;
        background: none;
        border: 2px solid $gray-300;
        color: $gray-700;
        span {
          position: absolute;
          top: -6px;
          right: -6px;
          height: 22px;
          width: 22px;
          line-height: 18px;
          font-size: 16px;
          color: white;
          border: 3px solid $gray-50;
          border-radius: 50% !important;
        }
        &.step-active {
          background: $primary;
          color: $white;
          border-color: $primary;
        }
      }
    }
  }
  @media screen and(max-width:767px) {
    max-width: 100% !important;
    .card {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    .step {
      &::before {
        display: none;
      }
    }
  }
  @media screen and(min-width:768px) {
    .card {
      border-bottom-right-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }
  }
}

.tab-pane-car {
  min-height: '490px';
}

.duration-input {
  width: 100px !important;
}

input.duration-input[type='number']::-webkit-inner-spin-button,
input.duration-input[type='number']::-webkit-outer-spin-button {
  opacity: 1;
}

.release {
  margin-bottom: 0;
}

.approval-comment-info {
  position: absolute;
  z-index: 2;
  right: 0px;
  bottom: 12px;
  color: white;
  background: $info;
  width: 43px;
  text-align: right;
  padding-right: 10px;
  border-radius: 20px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.release-img {
  overflow: hidden;
}
.release-img .img-wrap {
  width: 100%;
  height: 0;
  display: block;
  position: relative;
  // min-height: 200px;
  padding-bottom: 56%;
  background: $gray-300;
  background: $gray-300;
  background: linear-gradient(180deg, rgba(208, 208, 208, 1) 0%, rgba(208, 208, 208, 0) 100%);
}
.release-img .svg_image {
  width: 100%;
  max-width: 80px;
  transform: translateY(-50%);
  top: 50%;
  position: absolute;
}
.release-img .img-wrap img {
  position: absolute;
  width: 100%;
  height: 100%;
  left: -1000%;
  right: -1000%;
  top: -1000%;
  bottom: -1000%;
  margin: auto;
  -o-object-fit: cover;
  object-fit: cover;
}
.release-type svg {
  fill: $gray-500;
  width: 46px;
}
.release .release-status {
  top: 1rem;
  left: 1rem;
  width: auto;
  text-align: left;
}
.release .release-split {
  z-index: 1;
  left: 1rem;
  top: 3rem;
  width: auto;
  position: absolute;
}

/* Container query to show release in horizontal */

.carousel-item {
  container-type: inline-size;
  container-name: carousel-item;
}
@container carousel-item (min-width: 576px) {
  .release {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, auto);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    .release-img {
      grid-area: 1 / 1 / 5 / 2;
      border-top-right-radius: 0;
      border-bottom-left-radius: 1rem;
      border: none;
      .img-wrap {
        padding: 0;
        height: 100%;
      }
    }
    .release-title {
      grid-area: 1 / 2 / 2 / 4;
    }
    .release-details {
      grid-area: 2 / 2 / 3 / 4;
    }
    .release-actions {
      grid-area: 3 / 2 / 4 / 4;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
    .release-completion {
      grid-area: 4 / 2 / 5 / 4;
      border-radius: 0;
      border-bottom-right-radius: 1rem;
      padding: 1rem;
    }
  }
}

//////////////////////////////////////////////////////
/* RELEASE TYPE ( Selector in Upload Tool ) */
//////////////////////////////////////////////////////
.release-type {
  * {
    transition: $transition-short;
    font-family: $font-family-sans-serif;
    letter-spacing: 0;
  }
  label {
    border-radius: 8px;
    .card {
      box-shadow: none;
      background-color: $white;
      width: 100%;
      box-shadow: $box-shadow-xs;
      border-radius: 8px;
      overflow: hidden;
      &:hover {
        background-color: white;
      }
      svg {
        fill: $muted;
      }
    }
  }
  &:hover,
  &:active,
  &:checked {
    .card {
      border-color: $primary !important;
      background-color: $white;
      box-shadow: $box-shadow-lg;
    }
  }
  .btn-check:active + .btn-primary .card,
  .btn-check:checked + .btn-primary .card {
    border-color: $primary !important;
    background-color: $white;
    box-shadow: $box-shadow-lg;
    h4 {
      color: $primary-700;
    }
    svg {
      fill: $primary;
    }
  }
}

//////////////////////////////////////////////////////
/* RELEASE PLATFORM LOGOS ( Checkboxes + just logos */
//////////////////////////////////////////////////////

.platform-logos,
.platforms {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(158px, 1fr));
  grid-gap: 1.5rem;
  &.platforms-sm {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    grid-gap: 1rem;
    .custom-check,
    .platform-logo {
      height: 100px;
    }
  }
  * {
    transition: all 0.3s ease;
  }
  /* when they are custom checkboxes */
  .custom-check {
    padding: 0;
    position: relative;
    height: 140px;
    .form-check {
      padding: 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      box-shadow: $box-shadow-xs;
      border-radius: 6px;
      overflow: hidden;
      outline: 1px solid $gray-300;
      &:hover {
        box-shadow: $box-shadow-sm;
        outline: 1px solid $gray-500;
        cursor: pointer;
      }
      input[type='checkbox'] {
        -webkit-appearance: none;
        appearance: none;
        margin: 0;
        font: inherit;
        color: currentColor;
        border: none;
        display: grid;
        place-content: center;
        text-align: center;
        width: 100%;
        filter: grayscale(1);
        border-radius: 0;
        background: white;
        flex-grow: 1;
        height: auto;
        &:checked {
          filter: grayscale(0);
          &::before {
            opacity: 1;
          }
          &::after {
            opacity: 1;
          }
        }
        &:hover {
          filter: grayscale(0);
          cursor: pointer;
          &::before {
            opacity: 1;
          }
        }
        &::before {
          position: absolute;
          transition: opacity 0.3s ease;
          opacity: 0.35;
        }
        &::after {
          content: '';
          position: absolute;
          top: 4px;
          right: 4px;
          width: 22px;
          height: 22px;
          line-height: 22px;
          border-radius: 4px;
          background: $primary;
          opacity: 0;
          content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 -50 512 512'%3E %3Cpath fill='%23FFF' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E %3C/svg%3E");
        }
      }
      label {
        position: relative;
        font-weight: 400;
        height: 34px;
        white-space: nowrap;
        padding: 0.375rem 0.75rem;
        width: 100%;
        background: $gray-100;
        &:hover {
          cursor: pointer;
        }
      }
      &:has(input[type='checkbox']:checked) {
        outline: 2px solid $primary;
        box-shadow: $box-shadow-lg;
      }
    }
  }
  /* when they are just logos */
  .platform-logo {
    height: 140px;
    display: flex;
    flex-direction: column;
    box-shadow: $box-shadow-xs;
    border-radius: 6px;
    overflow: hidden;
    outline: 1px solid $gray-300;
    background-color: white;
    &::before {
      opacity: 1;
    }
  }
  /* if they are either: Logo sizes */
  .custom-check input[type='checkbox']::before,
  .platform-logo::before {
    content: '';
    width: 66%;
    height: 60%;
    margin: 10% 17%;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }
}

/* LOGOS */
.custom-check.logo-Youtube input[type='checkbox']::before,
.logo-Youtube.platform-logo::before {
  background-image: url(../../../images/platforms/Youtube.svg);
}
.custom-check.logo-YoutubeMusic input[type='checkbox']::before,
.logo-YoutubeMusic.platform-logo::before {
  background-image: url(../../../images/platforms/YoutubeMusic.svg);
}
.custom-check.logo-KKBOX input[type='checkbox']::before,
.logo-KKBOX.platform-logo::before {
  background-image: url(../../../images/platforms/KKBOX.svg);
}
.custom-check.logo-Tidal input[type='checkbox']::before,
.logo-Tidal.platform-logo::before {
  background-image: url(../../../images/platforms/TIDAL.svg);
}
.custom-check.logo-Tencent input[type='checkbox']::before,
.logo-Tencent.platform-logo::before {
  background-image: url(../../../images/platforms/Tencent.svg);
}
.custom-check.logo-SoundCloud input[type='checkbox']::before,
.logo-SoundCloud.platform-logo::before {
  background-image: url(../../../images/platforms/SoundCloud.svg);
}
.custom-check.logo-Audiomack input[type='checkbox']::before,
.logo-Audiomack.platform-logo::before {
  background-image: url(../../../images/platforms/Audiomack.svg);
}
.custom-check.logo-TikTok input[type='checkbox']::before,
.logo-TikTok.platform-logo::before {
  background-image: url(../../../images/platforms/TikTok.svg);
}
.custom-check.logo-Boomplay input[type='checkbox']::before,
.logo-Boomplay.platform-logo::before {
  background-image: url(../../../images/platforms/Boomplay.svg);
}
.custom-check.logo-Amazon input[type='checkbox']::before,
.logo-Amazon.platform-logo::before {
  background-image: url(../../../images/platforms/Amazon.svg);
}
.custom-check.logo-AmazonMusic input[type='checkbox']::before,
.logo-AmazonMusic.platform-logo::before {
  background-image: url(../../../images/platforms/AmazonMusic.svg);
}
.custom-check.logo-SevenDigital input[type='checkbox']::before,
.logo-SevenDigital.platform-logo::before {
  background-image: url(../../../images/platforms/7digital.svg);
}
.custom-check.logo-TidalVideo input[type='checkbox']::before,
.logo-TidalVideo.platform-logo::before {
  background-image: url(../../../images/platforms/TIDALVideo.svg);
}
.custom-check.logo-Gracenote input[type='checkbox']::before,
.logo-Gracenote.platform-logo::before {
  background-image: url(../../../images/platforms/Gracenote.svg);
}
.custom-check.logo-AudibleMagic input[type='checkbox']::before,
.logo-AudibleMagic.platform-logo::before {
  background-image: url(../../../images/platforms/AudibleMagic.svg);
}
.custom-check.logo-iHeartRadio input[type='checkbox']::before,
.logo-iHeartRadio.platform-logo::before {
  background-image: url(../../../images/platforms/iHeartRadio.svg);
}
.custom-check.logo-iMusica input[type='checkbox']::before,
.logo-iMusica.platform-logo::before {
  background-image: url(../../../images/platforms/iMusica.png);
}
.custom-check.logo-Deezer input[type='checkbox']::before,
.logo-Deezer.platform-logo::before {
  background-image: url(../../../images/platforms/Deezer.svg);
}
.custom-check.logo-Anghami input[type='checkbox']::before,
.logo-Anghami.platform-logo::before {
  background-image: url(../../../images/platforms/Anghami.svg);
}
.custom-check.logo-JioSaavn input[type='checkbox']::before,
.logo-JioSaavn.platform-logo::before {
  background-image: url(../../../images/platforms/JioSaavn.svg);
}
.custom-check.logo-Spotify input[type='checkbox']::before,
.logo-Spotify.platform-logo::before {
  background-image: url(../../../images/platforms/Spotify.svg);
}
.custom-check.logo-QOBUZ input[type='checkbox']::before,
.logo-QOBUZ.platform-logo::before {
  background-image: url(../../../images/platforms/QOBUZ.svg);
}
.custom-check.logo-Napster input[type='checkbox']::before,
.logo-Napster.platform-logo::before {
  background-image: url(../../../images/platforms/Napster.svg);
}
.custom-check.logo-AWA input[type='checkbox']::before,
.logo-AWA.platform-logo::before {
  background-image: url(../../../images/platforms/AWA.svg);
}
.custom-check.logo-Facebook input[type='checkbox']::before,
.logo-Facebook.platform-logo::before {
  background-image: url(../../../images/platforms/Facebook.svg);
}
.custom-check.logo-Mixcloud input[type='checkbox']::before,
.logo-Mixcloud.platform-logo::before {
  background-image: url(../../../images/platforms/Mixcloud.svg);
}
.custom-check.logo-FacebookVideo input[type='checkbox']::before,
.logo-FacebookVideo.platform-logo::before {
  background-image: url(../../../images/platforms/FacebookVideo.svg);
}
.custom-check.logo-TouchTunes input[type='checkbox']::before,
.logo-TouchTunes.platform-logo::before {
  background-image: url(../../../images/platforms/TouchTunes.svg);
}
.custom-check.logo-default input[type='checkbox']::before,
.logo-default.platform-logo::before {
  background-image: url(../../../images/platforms/default.svg);
}
.custom-check.logo-NetEase input[type='checkbox']::before,
.logo-NetEase.platform-logo::before {
  background-image: url(../../../images/platforms/Netease.png);
}
.custom-check.logo-Pandora input[type='checkbox']::before,
.logo-Pandora.platform-logo::before {
  background-image: url(../../../images/platforms/Pandora.svg);
}
.custom-check.logo-iTunes input[type='checkbox']::before,
.logo-iTunes.platform-logo::before {
  background-image: url(../../../images/platforms/AppleMusic.svg);
}
.custom-check.logo-Slacker input[type='checkbox']::before,
.logo-Slacker.platform-logo::before {
  background-image: url(../../../images/platforms/Slacker.png);
}
.custom-check.logo-AcrCloud input[type='checkbox']::before,
.logo-AcrCloud.platform-logo::before {
  background-image: url(../../../images/platforms/AcrCloud.svg);
}
.custom-check.logo-Adaptr input[type='checkbox']::before,
.logo-Adaptr.platform-logo::before {
  background-image: url(../../../images/platforms/Adaptr-fix.svg);
}
.custom-check.logo-Ami input[type='checkbox']::before,
.logo-Ami.platform-logo::before {
  background-image: url(../../../images/platforms/Ami-fix.svg);
}
.custom-check.logo-Flo input[type='checkbox']::before,
.logo-Flo.platform-logo::before {
  background-image: url(../../../images/platforms/Flo-fix.svg);
}
.custom-check.logo-iMusicaAsDistro input[type='checkbox']::before,
.logo-iMusicaAsDistro.platform-logo::before {
  background-image: url(../../../images/platforms/iMusicaAsDistro.svg);
}
.custom-check.logo-Jaxsta input[type='checkbox']::before,
.logo-Jaxsta.platform-logo::before {
  background-image: url(../../../images/platforms/Jaxsta-fix.svg);
}
.custom-check.logo-JOOX input[type='checkbox']::before,
.logo-JOOX.platform-logo::before {
  background-image: url(../../../images/platforms/JOOX.svg);
}
.custom-check.logo-Leaplay input[type='checkbox']::before,
.logo-Leaplay.platform-logo::before {
  background-image: url(../../../images/platforms/Leaplay.svg);
}
.custom-check.logo-Lickd input[type='checkbox']::before,
.logo-Lickd.platform-logo::before {
  background-image: url(../../../images/platforms/Lickd.svg);
}
.custom-check.logo-Peloton input[type='checkbox']::before,
.logo-Peloton.platform-logo::before {
  background-image: url(../../../images/platforms/Peloton.svg);
}
.custom-check.logo-Trebel input[type='checkbox']::before,
.logo-Trebel.platform-logo::before {
  background-image: url(../../../images/platforms/Trebel.svg);
}
.custom-check.logo-VevoVideo input[type='checkbox']::before,
.logo-VevoVideo.platform-logo::before {
  background-image: url(../../../images/platforms/VevoVideo.svg);
}
.custom-check.logo-TunedGlobal input[type='checkbox']::before,
.logo-TunedGlobal.platform-logo::before {
  background-image: url(../../../images/platforms/TunedGlobal.svg);
}
.custom-check.logo-SongCentral input[type='checkbox']::before,
.logo-SongCentral.platform-logo::before {
  background-image: url(../../../images/platforms/SongCentral.svg);
}
.custom-check.logo-Alibaba input[type='checkbox']::before,
.logo-Alibaba.platform-logo::before {
  background-image: url(../../../images/platforms/Alibaba.svg);
}

.table-topplaylisted tr td {
  height: 48px;
  overflow: hidden;
}

.releaseCard {
  .img-wrap {
    background-size: cover;
    background-position: center;
    height: 0;
    padding-bottom: 75%;
  }
  @media (min-width: 1900px) {
    .img-wrap {
      height: 100%;
      padding-bottom: 0;
    }
  }
}
