/**********************************************/
/* BODY */
/**********************************************/
$body-bg: $gray-100;
$body-color:$gray-900;
$body-text-align: null;

/**********************************************/
/* LOGIN */
/**********************************************/
$login-bg: $black; 
$login-card-bg: $dark;
.login-card-bg { background: $login-card-bg!important; box-shadow: 0 1rem 1.25rem rgba(0, 0, 0, 0.2)!important; } 


/**********************************************/
/* TOPBAR */
/**********************************************/
$topbar-height: 72px;

// Topbar Background
$bg-topbar:                     $dark;
$bg-topbar-dark:                $dark;

// Topbar Search
$bg-topbar-search:              #f1f3fa;
$bg-topbar-dark-search:         #3c4655;

/**********************************************/
/* LEFTBAR / RIGHTBAR */
/**********************************************/
// Background left-sidebar
$bg-leftbar:                        $dark;
$bg-leftbar-light:                  $white;
$bg-leftbar-dark:                   $dark;
$bg-topnav:                         $white;

// Deatched left sidenav
$bg-detached-leftbar:               $white;

// Logo Display
$logo-light-display:                block;
$logo-dark-display:                 none;

// Logo auth display
$logo-auth-light-display:           none;
$logo-auth-dark-display:            block;

// Leftbar width
$leftbar-width:                     260px;

// Boxed Layout
$boxed-layout-width:                1300px;
$boxed-layout-bg:                   $white;

// Menu item colors (Default-dark)
$menu-item:                         $gray-200;
$menu-item-hover:                   $gray-300;
$menu-item-active:                  $white;

// Menu item colors (light)
$menu-item-light:                   $gray-200;
$menu-item-light-hover:             $gray-300;
$menu-item-light-active:            $white;

// Detached Menu item colors
$menu-item-dark:                    $gray-100;
$menu-item-dark-hover:              $gray-300;
$menu-item-dark-active:             $white;

// Dark sidenav Menu item color
$sidebar-dark-menu-title:           $gray-200;
$sidebar-dark-menu-item:            $gray-400;
$sidebar-dark-menu-item-hover:      $gray-300;
$sidebar-dark-menu-item-active:     $white;

// Rightbar Width
$rightbar-width:                    280px;
$rightbar-bg:                       $white;
$rightbar-title-bg:                 $dark;
$rightbar-title-color:              $white;
$rightbar-title-btn-bg:             $primary;
$rightbar-title-btn-color:          $white;
$rightbar-overlay-bg:               $gray-700;
