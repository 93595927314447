/**********************************************/
/* COLOR SYSTEM */
/**********************************************/
// GRAYS
$white: #fff;
$gray: #737373 !default;
$gray-50: #f8f8f8;
$gray-100: #f0f0f0;
$gray-200: #e8e8e8;
$gray-300: #d0d0d0;
$gray-400: #a1a1a1;
$gray-500: #737373;
$gray-600: #5b5b5b;
$gray-700: #444444;
$gray-800: #333;
$gray-900: #212121;
$gray-950: #151515;
$black: #000;

$primary: #0197f6;
$secondary: #2239cd;
$tertiary: #727cf5;
$info: #0d0f51;
$success: #00b815;
$danger: #b0280c;
$warning: #ff700a;
$caution: #f9bc0d;
$muted: #8f979d;

// PRIMARY - BRAND
$primary: #0197f6 !default;
$primary-50: #ccf0f7;
$primary-100: #99e1ef;
$primary-200: #66d2e7;
$primary-300: #00b4d7;
$primary-400: #33c3df;
$primary-500: #0197f6;
$primary-600: #00a2c2;
$primary-700: #0090ac;
$primary-800: #007e97;
$primary-900: #005a6c;
$primary-950: #003640;

// SECONDARY - BRAND
$secondary: #2239cd !default;
$secondary-50: #ece6eb;
$secondary-100: #d8ccd7;
$secondary-200: #b199af;
$secondary-300: #8b6786;
$secondary-400: #64345e;
$secondary-500: #2239cd;
$secondary-600: #370131;
$secondary-700: #31012b;
$secondary-800: #250120;
$secondary-900: #180016;
$secondary-950: #120010;

// TERTIARY
$tertiary: #727cf5 !default;
$tertiary-50: #e4e6fd;
$tertiary-100: #d1d4fc;
$tertiary-200: #bec3fa;
$tertiary-300: #abb1f9;
$tertiary-400: #989ff8;
$tertiary-500: #727cf5;
$tertiary-600: #5f6af4;
$tertiary-700: #4c59f2;
$tertiary-800: #3947f1;
$tertiary-900: #1826e2;
$tertiary-950: #0315db;

// QUATERNARY
$quaternary: #4b21a8 !default;
$quaternary-50: #eae3f9;
$quaternary-100: #cdbdf2;
$quaternary-200: #b096ea;
$quaternary-300: #9470e3;
$quaternary-400: #7749db;
$quaternary-500: #4b21a8;
$quaternary-600: #3a1982;
$quaternary-700: #29125b;
$quaternary-800: #180a35;
$quaternary-900: #06030f;
$quaternary-950: #000000;

// QUINARY
$quinary: #5bc0eb !default;

// SUCCESS
$success: #17b26a !default;
$success-50: #ecfdf3;
$success-100: #dcfae6;
$success-200: #abefc6;
$success-300: #75e0a7;
$success-400: #47cd89;
$success-500: #17b26a;
$success-600: #079455;
$success-700: #067647;
$success-800: #085d3a;
$success-900: #074d31;
$success-950: #053321;

// CAUTION
$caution: #f8c425 !default;
$caution-50: #fef4d7;
$caution-100: #fdecb9;
$caution-200: #fce49c;
$caution-300: #fbdc7e;
$caution-400: #fad460;
$caution-500: #f8c425;
$caution-600: #f6bc08;
$caution-700: #d9a507;
$caution-800: #bb8f06;
$caution-900: #9e7805;
$caution-950: #806104;

// WARNING
$warning: #ec7d10 !default;
$warning-50: #fffaeb;
$warning-100: #fdf1e4;
$warning-200: #fadab9;
$warning-300: #f7c28e;
$warning-400: #f4ab63;
$warning-500: #ec7d10;
$warning-600: #c1660d;
$warning-700: #964f0a;
$warning-800: #6b3907;
$warning-900: #402204;
$warning-950: #150b01;

// DANGER
$danger: #bd0f32 !default;
$danger-50: #fef3f2;
$danger-100: #fbd0d9;
$danger-200: #f8a1b2;
$danger-300: #f4718c;
$danger-400: #f04265;
$danger-500: #bd0f32;
$danger-600: #9c0c29;
$danger-700: #7b0a21;
$danger-800: #5a0718;
$danger-900: #39050f;
$danger-950: #180206;

// OTHERS
$info: #0d0f51 !default;
$lightblue: #f2f6fa !default;
$muted: $gray-500 !default;
$light: $gray-100 !default;
$dark: $gray-900 !default;

// WEBSITE SPECIFIC
$color-admin: $black;
$color-sm: #8c2df0;
$color-ee: #00b4d7;
$color-ea: #0186a9;
$color-mea: #005376;
$color-ml: #5EB607;
$color-sl: $gray-600;
$color-sp: $gray-400;
$color-pub: #3d0136;

/**********************************************/
/* COLORS - MAPPING */
/**********************************************/
$theme-colors: (
  // GRAYS
  'gray': $gray,
  'gray-50': $gray-50,
  'gray-100': $gray-100,
  'gray-200': $gray-200,
  'gray-300': $gray-300,
  'gray-400': $gray-400,
  'gray-500': $gray-500,
  'gray-600': $gray-600,
  'gray-700': $gray-700,
  'gray-800': $gray-800,
  'gray-900': $gray-900,
  'gray-950': $gray-950,

  // PRIMARY - BRAND
  'primary': $primary,
  'primary-50': $primary-50,
  'primary-100': $primary-100,
  'primary-200': $primary-200,
  'primary-300': $primary-300,
  'primary-400': $primary-400,
  'primary-500': $primary-500,
  'primary-600': $primary-600,
  'primary-700': $primary-700,
  'primary-800': $primary-800,
  'primary-900': $primary-900,
  'primary-950': $primary-950,

  // SECONDARY
  'secondary': $secondary,
  'secondary-50': $secondary-50,
  'secondary-100': $secondary-100,
  'secondary-200': $secondary-200,
  'secondary-300': $secondary-300,
  'secondary-400': $secondary-400,
  'secondary-500': $secondary-500,
  'secondary-600': $secondary-600,
  'secondary-700': $secondary-700,
  'secondary-800': $secondary-800,
  'secondary-900': $secondary-900,
  'secondary-950': $secondary-950,

  // TERTIARY
  'tertiary': $tertiary,
  'tertiary-50': $tertiary-50,
  'tertiary-100': $tertiary-100,
  'tertiary-200': $tertiary-200,
  'tertiary-300': $tertiary-300,
  'tertiary-400': $tertiary-400,
  'tertiary-500': $tertiary-500,
  'tertiary-600': $tertiary-600,
  'tertiary-700': $tertiary-700,
  'tertiary-800': $tertiary-800,
  'tertiary-900': $tertiary-900,
  'tertiary-950': $tertiary-950,

  // QUATERNARY
  'quaternary': $quaternary,
  'quaternary-50': $quaternary-50,
  'quaternary-100': $quaternary-100,
  'quaternary-200': $quaternary-200,
  'quaternary-300': $quaternary-300,
  'quaternary-400': $quaternary-400,
  'quaternary-500': $quaternary-500,
  'quaternary-600': $quaternary-600,
  'quaternary-700': $quaternary-700,
  'quaternary-800': $quaternary-800,
  'quaternary-900': $quaternary-900,
  'quaternary-950': $quaternary-950,

  // QUINARY
  'quinary': $quinary,

  // SUCCESS
  'success': $success,
  'success-50': $success-50,
  'success-100': $success-100,
  'success-200': $success-200,
  'success-300': $success-300,
  'success-400': $success-400,
  'success-500': $success-500,
  'success-600': $success-600,
  'success-700': $success-700,
  'success-800': $success-800,
  'success-900': $success-900,
  'success-950': $success-950,

  // CAUTION
  'caution': $caution,
  'caution-50': $caution-50,
  'caution-100': $caution-100,
  'caution-200': $caution-200,
  'caution-300': $caution-300,
  'caution-400': $caution-400,
  'caution-500': $caution-500,
  'caution-600': $caution-600,
  'caution-700': $caution-700,
  'caution-800': $caution-800,
  'caution-900': $caution-900,
  'caution-950': $caution-950,

  // WARNING
  'warning': $warning,
  'warning-50': $warning-50,
  'warning-100': $warning-100,
  'warning-200': $warning-200,
  'warning-300': $warning-300,
  'warning-400': $warning-400,
  'warning-500': $warning-500,
  'warning-600': $warning-600,
  'warning-700': $warning-700,
  'warning-800': $warning-800,
  'warning-900': $warning-900,
  'warning-950': $warning-950,

  // DANGER
  'danger': $danger,
  'danger-50': $danger-50,
  'danger-100': $danger-100,
  'danger-200': $danger-200,
  'danger-300': $danger-300,
  'danger-400': $danger-400,
  'danger-500': $danger-500,
  'danger-600': $danger-600,
  'danger-700': $danger-700,
  'danger-800': $danger-800,
  'danger-900': $danger-900,
  'danger-950': $danger-950,

  // OTHERS
  'info': $info,
  'muted': $muted,
  'lightblue': $lightblue,
  'light': $light,
  'dark': $dark,

  // WEBSITE SPECIFIC
  'color-admin': $color-admin,
  'color-sm': $color-sm,
  'color-ee': $color-ee,
  'color-ea': $color-ea,
  'color-mea': $color-mea,
  'color-ml': $color-ml,
  'color-sl': $color-sl,
  'color-sp': $color-sp,
  'color-pub': $color-pub
);
// scss-docs-end theme-colors-map

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio: 1.8;

// Customize the light and dark text colors for use in our color contrast function.
$color-contrast-dark: $gray-900;
$color-contrast-light: $white;

$component-active-color: $white;
$component-active-bg: $primary;

/**********************************************/
/* GRADIENT */
/**********************************************/
// The gradient which is added to components if `$enable-gradients` is `true`
// This gradient is also added to elements with `.bg-gradient`
// scss-docs-start variable-gradient
$gradient: linear-gradient(180deg, rgba($white, 0.15), rgba($white, 0));
// scss-docs-end variable-gradient
