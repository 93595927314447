///////////////////////////////////////////////////
/* BADGES */
///////////////////////////////////////////////////
.badge {
  height: 24px;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  gap: 0.25em;
  transition: $transition-short;
  i {
    margin-left: -0.25em;
    font-size: 1em;
  }
  svg {
    height: 1em;
    width: 1em;
    margin-left: -0.2em;
    fill: currentColor;
  }

  /* Badge SIZES */
  &.badge-xs {
    // height: 28px; font-size: 0.875rem;
  }
  &.badge-sm {
    height: 28.89px; font-size: 0.7875rem;
  }
  &.badge-md {
    height: 35.58px; font-size: 0.9rem;
    svg { margin-left: -0.15rem;}
  }
  &.badge-lg {
    height: 42px; font-size: 1rem; padding: 0 1em; gap: 0.375em;
    svg { margin-left: 0;}
  }

  /* Badge: TYPE */
  &.badge-type {
    border-radius: $border-radius-sm;
    border: 1px solid $primary-200;
    background-color: $primary-50;
    color: $primary;
    &.type-distribution {
      border: 1px solid $primary-300;
      background-color: $primary-50;
      color: $primary-600;
    }
    &.type-asset {
      border: 1px solid $secondary-200;
      background-color: $secondary-50;
      color: $secondary;
    }
    &.type-pitch {
      border: 1px solid $tertiary-200;
      background-color: $tertiary-50;
      color: $tertiary;
    }
    &.type-license {
      border: 1px solid $quaternary-200;
      background-color: $quaternary-50;
      color: $quaternary;
    }
  }

  /* Badge: PERCENTAGE (up/down/equal) */
  &.badge-percentage {
    border-radius: $border-radius-pill;
    border: 1px solid;
    font-weight: 600;
    &.percentage-up {
      background-color: $success-50;
      color: $success;
      border-color: $success-300;
      svg {
        fill: $success;
      }
    }
    &.percentage-down {
      background-color: $warning-50;
      color: $warning;
      border-color: $warning-300;
      svg {
        fill: $warning;
      }
    }
    &.percentage-equal {
      background-color: $gray-50;
      color: $gray;
      border-color: $gray-300;
      svg {
        fill: $gray-500;
      }
    }
  }

  /* Badge: STATUS (info/success/caution/warning/danger) */
  &.badge-status {
    border-radius: $border-radius-pill;
    border: 1px solid;
    &.status-neutral {
      background-color: $gray-50;
      color: $gray;
      border-color: $gray-300;
    }
    &.status-info {
      background-color: $secondary-50;
      color: $secondary-400;
      border-color: $secondary-200;
    }
    &.status-success {
      background-color: $success-100;
      color: $success-600;
      border-color: $success-300;
    }
    &.status-caution {
      background-color: $caution-100;
      color: $caution-800;
      border-color: $caution-300;
    }
    &.status-warning {
      background-color: $warning-100;
      color: $warning-600;
      border-color: $warning-300;
    }
    &.status-danger {
      background-color: $danger-100;
      color: $danger-600;
      border-color: $danger-200;
    }
  }

  /* Badge: SITE */
  &.badge-site {
    border-radius: $border-radius-pill;
    border: none;
    color: $white;
    font-weight: 500;
    padding: 0.35em 1em 0.35em 0.75em;
    &::before {
      content: '';
      width: 6px;
      height: 6px;
      background: $white;
      border-radius: 100%;
      margin-right: 0.25em;
    }
    &.site-admin {
      background-color: $black;
    }
    &.site-sm {
      background-color: $color-sm;
    }
    &.site-ee {
      background-color: $color-ee;
    }
    &.site-ea {
      background-color: $color-ea;
    }
    &.site-mea {
      background-color: $color-mea;
    }
    &.site-sl {
      background-color: $color-sl;
    }
    &.site-ml {
      background-color: $color-ml;
    }
    &.site-sp {
      background-color: $color-sp;
    }
  }
  /* Badge: CMS */
  &.badge-cms {
    border-radius: $border-radius-pill;
    border: none;
    color: $white;
    font-weight: 500;
    padding: calc(0.35em + 1px) 1em 0.35em 1em;
    text-transform: uppercase;
    position: relative;
    &.cms-ee {
      background-color: $color-ee;
    }
    &.cms-ea {
      background-color: $color-ea;
    }
    &.cms-mea {
      background-color: $color-mea;
    }
    &.cms-pub {
      background-color: $color-pub;
    }
  }

  /* Badge: USER */
  &.badge-user {
    border-radius: $border-radius-pill;
    border: 1px solid;
    font-weight: 600;
    padding: 0.35em 1em 0.35em 0.75em;
    background-color: $info;
    color: $white;
    border-color: $info;
    z-index: 2;
    position: relative;
    font-size: 0.9em;
    i,
    svg {
      margin-right: 0.25em;
    }
    &:hover {
      background-color: $primary-500;
      color: $white;
      border-color: $primary-500;
      text-decoration: none;
    }
    &.user-notset {
      background-color: $warning-100;
      color: $warning-600;
      border-color: $warning-200;
    }
    &.user-deleted {
      background-color: $danger-100;
      color: $danger-600;
      border-color: $danger-200;
      small {
        font-weight: 400;
      }
      &:hover {
        background-color: $danger;
        color: $white;
        border-color: $danger;
        text-decoration: none;
      }
    }
    &.badge-childuser {
      padding: 0.35em 0.75em 0.35em 2rem;
      margin-left: -1.75rem;
      z-index: 1;
      background-color: $primary-50;
      color: $primary-600;
      border-color: $primary-600;
      text-decoration: none;
      font-size: 0.75em;
      &.user-deleted {
        background-color: $danger-500;
        color: $white;
        border-color: $danger-500;
      }
    }
    &.badge-parentuser {
      padding: 0.35em 0.75em 0.35em 2rem;
      margin-left: -1.75rem;
      z-index: 1;
      background-color: $primary-50;
      color: $primary-600;
      border-color: $primary-600;
      text-decoration: none;
      font-size: 0.75em;
      &.user-deleted {
        background-color: $danger-500;
        color: $white;
        border-color: $danger-500;
      }
    }
    &.badge-donotpay {
      padding: 0.35em 0.75em 0.35em 2rem;
      margin-left: -1.75rem;
      z-index: 1;
      background-color: $danger-500;
      color: $white;
      border-color: $danger-500;
      text-decoration: none;
      font-size: 0.75em;
      text-transform: uppercase;
    }
  }

  /* Badge: USER Label */
  &.badge-user-label {
    background-color: $gray-100;
    color: $gray;
    border: 1px solid $gray-400;
    text-transform: capitalize;
  }
  /* Badge: USER Role */
  &.badge-user-role {
    background-color: $secondary-50;
    color: $secondary-400;
    border: 1px solid $secondary-200;
    text-transform: capitalize;
  }

  /* Badge: BOOLEAN (yes/no/outlined-yes/outlined-no) */
  &.badge-boolean {
    border-radius: $border-radius-pill;
    border: none;
    color: $white;
    text-transform: uppercase;
    padding: 0.35em 1em 0.35em 0.75em;
    &.badge-yes {
      background-color: $success;
    }
    &.badge-outlined-yes {
      background-color: $success-50;
      color: $success;
    }
    &.badge-outlined-yes {
      background-color: $success-50;
      color: $success;
      border: 1px solid $success;
    }
    &.badge-no {
      background-color: $danger;
    }
    &.badge-outlined-no {
      background-color: $danger-50;
      color: $danger;
      border: 1px solid $danger;
    }
    i,
    svg {
      fill: currentColor;
      margin-right: 0.25em;
    }
  }

  /* Badge: PAYMENT */
  &.badge-paymentmethod {
    color: $dark;
    padding: 0 0.5em;
    height: auto;
    box-shadow: none;
    width: 100%;
    font-size: 12px;
    text-transform: uppercase !important;
    justify-content: start;
    gap: 0.5rem;
    svg {
      height: 1.2rem !important;
      width: 1.2rem !important;
      flex-shrink: 0;
      margin: 0;
    }
  }

  /* Badge: PERFORMANCE - shares styles with list-group-numbered-performance */
  &.badge-performance {
    background-color: transparent;
    min-width: 24px;
    display: inline-block;
    text-align: center;
    border-radius: 4px;
    margin-right: 1rem;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    color: $gray-500;
    border: 1px solid $gray-300;
    flex-shrink: 0;
    box-shadow: $box-shadow-sm;
    &.perf-first {
      background-color: $primary-900;
      border-color: $primary-900;
      color: white;
    }
    &.perf-second {
      background-color: $primary-700;
      border-color: $primary-700;
      color: white;
    }
    &.perf-third {
      background-color: $primary-500;
      border-color: $primary-500;
      color: white;
    }
  }
}

